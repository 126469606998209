import React, { useState, useRef, useContext } from "react";
import "./AddNewRooms.css";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { useQuery } from "react-query";
import { fetchFilters } from "../../../utils/filtersOperations";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MyContext from "../../../utils/MyContext";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";

function AddNewRooms() {
  const apiURL = process.env.REACT_APP_API_URL;

  const filtersEndpoint = "facilities";
  const [selectedFile, setSelectedFile] = useState([]);
  const [newRoomData, setNewRoomData] = useState({
    roomCredit: "",
    roomTitle: "",
    roomCapacity: "",
    roomFacilities: [],
    description: "",
    quickbook: false,
  });
  const fileInputRef = useRef(null);
  const [facilities, setFacilities] = useState([]);
  const { data, isLoading } = useQuery(
    ["skillsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        setFacilities(data.map((facility) => facility.title));
      },
    }
  );
  const token = localStorage.getItem("token");
  const animatedComponents = makeAnimated();
  // navigate function
  const navigate = useNavigate();
  console.log(newRoomData);

  const handleInputChange = (event, additionalDetails) => {
    if (additionalDetails?.name === "roomFacilities") {
      const filters = event.map((facility) => facility.value);
      setNewRoomData({
        ...newRoomData,
        [additionalDetails.name]: filters.join("|"),
      });
      return;
    }
    console.log("event target", event.target);
    const { name, value } = event.target;
    setNewRoomData({ ...newRoomData, [name]: value });
    console.log("handle change connected");
  };

  const handleImageRemove = (removeIndex) => {
    setSelectedFile(
      selectedFile.filter((file, index) => index !== removeIndex)
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (selectedFile.length + files.length > 5) {
      toast.warn("Maximum 5 images are allowed");
      return;
    }
    console.log(files);
    setSelectedFile([...selectedFile, ...files]);
  };

  const handleNewRoomSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form Data:", {
    //   file: selectedFile,
    //   ...newRoomData,
    // });

    try {
      const imageFormData = new FormData();
      selectedFile.map((file, index) => {
        imageFormData.append(`image`, file);
      });
      // imageFormData.append('image', selectedFile)

      const imageUploadResponse = await axios.post(
        `${apiURL}/v1/files/multiple`,
        imageFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let imagePaths = "";
      const imageDataArray = imageUploadResponse.data.body[0];
      imageDataArray.forEach((imageData, index) => {
        if (index === imageDataArray.length - 1) {
          imagePaths += imageData.filename;
        } else {
          imagePaths += imageData.filename + "|";
        }
      });
      // add here
      if (!selectedFile.length) {
        toast.warn("Please select an image");
        return;
      }
      console.log("image paths", imagePaths);
      const formData = new FormData();
      formData.append("files", imagePaths);
      // formData.append("credits", newRoomData.roomCredit);
      formData.append(
        "title",
        newRoomData.roomCapacity > 1
          ? `${newRoomData.roomTitle}|${newRoomData.roomCapacity}`
          : newRoomData.roomTitle
      );
      formData.append("facilities", newRoomData.roomFacilities);
      formData.append("description", newRoomData.description);
      formData.append("quickbook", newRoomData.quickbook);
      console.log(newRoomData.quickbook);
      const response = await axios.post(`${apiURL}/v1/rooms`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      console.log("response", response)
      navigate("/meetingrooms");
    } catch (error) {
      console.error("Error creating news:", error.message);
    }
  };
  const { contextValue, setContextValue } = useContext(MyContext);

  console.log("use context in meeting room", contextValue);
  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="addNewRoomWapper">
          <NavigateBack fill={"#3384b1"} onClick={() => window.location.href="/meetingrooms"}/>

            <div className="addNewFormWrapper">
              <form
                action=""
                className="addNewRoomForm"
                onSubmit={handleNewRoomSubmit}
              >
                <h2>Add new room</h2>
                <AsteriskText />
                <div
                  className="fileInput"
                  onDragOver={dragOverHandler}
                  onDrop={(event) => {
                    dropHandler(event, setSelectedFile, {
                      from: "rooms",
                      selectedFile: selectedFile,
                    });
                  }}
                >
                  <label htmlFor="files">Select Files</label>
                  {selectedFile.length > 0 && (
                    <ul className="imgNameList">
                      {selectedFile.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="removeImage"
                            onClick={() => handleImageRemove(index)}
                          >
                            <CloseIcon />
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                  <input
                    type="file"
                    name=""
                    id="files"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                {/* <div className="addNewFormInputWrapper creditField">
                  <label htmlFor="roomCredit">
                    Room Credits (Per Hour Basis) <Asterisk />
                  </label>
                  <br />
                  <input
                    type="number"
                    name="roomCredit"
                    placeholder="Enter room credits"
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (
                        !(
                          e.key === "Backspace" ||
                          e.key === "Delete" ||
                          e.key === "ArrowLeft" ||
                          e.key === "ArrowRight" ||
                          e.key === "Tab" ||
                          (e.key >= "0" && e.key <= "9")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                </div> */}
                <div className="addNewFormInputWrapper">
                  <label htmlFor="roomTitle">
                    Room Title <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="roomTitle"
                    placeholder="Enter room title"
                    maxLength={25}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {/* input field for capacity */}
                <div className="addNewFormInputWrapper">
                  <label htmlFor="roomCapacity">
                    Room Capacity(Number Of Seats)
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="number"
                    name="roomCapacity"
                    placeholder="Enter The room capacity"
                    maxLength={25}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (
                        !(
                          e.key === "Backspace" ||
                          e.key === "Delete" ||
                          e.key === "ArrowLeft" ||
                          e.key === "ArrowRight" ||
                          e.key === "Tab" ||
                          (e.key >= "0" && e.key <= "9")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                </div>
                {!isLoading && (
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="roomFacilities">Room Facilities
                      <Asterisk />
                    </label>
                    <br />
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={facilities.map((facility) => {
                        return { value: facility, label: facility };
                      })}
                      onChange={handleInputChange}
                      name="roomFacilities"
                      // menuPortalTarget={document.body}
                      // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                      className={`react-select-container ${
                        contextValue ? "indexClass" : ""
                      }`}
                      classNamePrefix="react-select"
                      classNames={{
                        container: (state) => "react-select-container",
                      }}
                      required
                    />
                  </div>
                )}
                <div className="addNewFormInputWrapper descheight">
                  <label htmlFor="description">Description 
                    <Asterisk />
                  </label>
                  <br />
                  <textarea
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                {/* <div className="setQuickBookingSection">
                  <div className="leftClass">
                    <h4>Set Quick Bookings</h4>
                  </div>
                  <div className="rightClass">
                    <input
                      type="checkbox"
                      name=""
                      id="toggleQuickBooking"
                      onChange={(e) =>
                        setNewRoomData({
                          ...newRoomData,
                          quickbook: e.target.checked,
                        })
                      }
                    />
                    <label htmlFor="toggleQuickBooking">
                      <div className="toggleText">
                        <span>ON</span>
                        <span>OFF</span>
                      </div>
                    </label>
                  </div>
                </div> */}
                <div className="addBtnWrapper">
                  <button type="submit">Add</button>
                </div>
              </form>
            </div>
          </div>
          {/* Content of the page */}
        </div>
      </div>
    </>
  );
}

export default AddNewRooms;
