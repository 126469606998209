import React, { useEffect, useState } from "react";
import "./NotificationWidget.css";
import BellIcon from "../../../src/assets/png/BellIcon.png";
import NFIHBellIcon from "../../../src/assets/svg/NFIHBellIcon.svg"
import { ReactComponent as NotificationDots } from "../../../src/assets/svg/NotificationDots.svg";
import { ReactComponent as NotificationWatch } from "../../../src/assets/svg/notificationWatch.svg";
import notificationProfile from "../../../src/assets/png/notificationProfile.png";

import { firestore } from "../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

function NotificationWidget() {
  const [isClicked, setIsClicked] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState(0);
  const notificationData = collection(firestore, "userrequest");
  
  useEffect(() => {
    fetchNotificationData();

    const handleClickOutside = (event) =>{
      if (!event.target.closest('.bellIconWrapper')) {
        setIsClicked(false);
      }
    };
    if (isClicked) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isClicked]);

  const fetchNotificationData = async () => {
    try{
      const data = await getDocs(notificationData);
      // in this const the data will be stored
      const notificationsData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setNotification(notificationsData);
      console.log("notification",notification);
      setNotificationNumber(notificationsData.filter(note => !note.isRead).length);
    }catch(error){
      console.log("Error fetching notifications", error)
    }
  };
  
  const handleNotification = async () => {
    if(!isClicked){
      await markNotificationsAsRead();
      setNotificationNumber(0);
    }
    setIsClicked(!isClicked);
    //console.log(notification);
  };
 
  const markNotificationsAsRead = async () =>{
    const unreadNotifications = notification.filter(note => !note.isRead);
    const updatePromises = unreadNotifications.map(note => {
      const notificationRef = doc(firestore, "userrequest", note.id);
      return updateDoc(notificationRef, { isRead: true });
    });
    await Promise.all(updatePromises);
    fetchNotificationData();
  };

 const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};
const getNotificationTime = (createdAt) => {
  const date = new Date(createdAt);
  const currentTime = new Date();
  const timeDifference = currentTime - date;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  if (hoursDifference < 24) {
    return `${hoursDifference}hrs`;
  } else {
    const daysDifference = Math.floor(hoursDifference / 24);
    return `${daysDifference}d`;
  }
};
  return (
    <div className="notif-container">
      <div className="bellIconWrapper" onClick={handleNotification}>
        <img src={NFIHBellIcon} alt="" id="notifImg" />
        <div className="notificationNumberDiv">
          <span>{notificationNumber}</span>
        </div>
      </div>

      {isClicked && (
        <div className="notificationTextWrapper show">
          <div className="notificationBar">
            <h3>Notifications</h3>
          </div>
          <div className="notifi-container">
             {notification.map((note) => (
              <div className="notificationClass">
                <div className="notificationProfileImg">
                  <img src={note.profileImage} alt="" className="notification-img" />
                </div>
                <div className="notificationDetails showDrawer">
                  <div className="usernameDiv">
                  <h2>{note.username}</h2>
                  </div>
                  <div className="messageDiv">
                  <p>
                    {note.message}
                  </p>
                  <p>{getNotificationTime(note.createdAt)}</p>
                  </div>
                  <div className="notificationTimeClass">
                    <div className="notifWatch">
                      <NotificationWatch id="watch-svg" />
                    </div>
                    <p>{formatDate(note.createdAt)}</p>
                  </div>
                </div>
              </div>
            ))}
             
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationWidget;
