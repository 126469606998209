import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import LoadingOverlay from "react-loading-overlay-ts";
import axios from "axios";
import { useParams } from "react-router-dom";
import NavigateBack from "../../utils/NavigateBack";

const EventDetails = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState({});
  const [eventBookings, setEventBookings] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("token");
        const eventsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/events/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEventDetails(eventsResponse?.data?.body[0]);
        const eventBookingsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/eventBookings/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEventBookings(eventBookingsResponse?.data?.body[0]);
      } catch (error) {}
    })();
  }, []);
  return (
    <LoadingOverlay active={false} spinner text="Loading your content...">
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          <div className="eventDetailsWrapper">
            <div className="navigate-svg-container">

          <NavigateBack fill={"#3384b1"} onClick={() => window.location.href="/events"} />
            </div>
            <div className="event_details_container">
              <header className="event_details_header">
                {eventDetails?.eventName}
              </header>
              <div className="event_details_cards">
                <div className="event_details_card">
                  <div className="event_detail_name">Registrations</div>
                  <div className="event_detail_count">
                    {eventDetails?.eventRegistrations}
                  </div>
                </div>
                <div className="event_details_card">
                  <div className="event_detail_name">Left Over</div>
                  <div className="event_detail_count">
                    {eventDetails?.eventCapacity -
                      eventDetails?.eventRegistrations}
                  </div>
                </div>
                <div className="event_details_card">
                  <div className="event_detail_name">Arrived Guests</div>
                  <div className="event_detail_count">
                    {eventDetails?.eventAttendees}
                  </div>
                </div>
              </div>
              <div className="event_capacity">
                Capacity {eventDetails?.eventCapacity}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default EventDetails;
