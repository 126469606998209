import React from "react";
// import { ReactComponent as MoreIcon } from "../../assets/svg/more-icon.svg"; // Assuming you have a "more" icon

import "./PolicyCard.css"; // External CSS for styling

const PolicyCard = ({ policy }) => {
  if (!policy) return <div>No policy data</div>;
  console.log("cardcard", policy)
  return (
    <div className="policyCard">
      <div className="cardHeader">
        <h3>{policy.policyName}</h3>
        <button className="moreIcon">
          {/* <MoreIcon /> */}
        </button>
      </div>
      <div className="cardContent">
        <p><strong>Version</strong>: {policy.policyVersion}</p>
        <p><strong>Status</strong>: {policy.visibility ? "Active" : "Inactive"}</p>
        <p><strong>Format</strong>: {policy.policyFormat}</p>
        <p><strong>Upload Date</strong>: {policy.policyUploadDate}</p>
        <p><strong>Date of Validity</strong>: {policy.policyValidityDate}</p>
      </div>
    </div>
  );
};

export default PolicyCard;
